import { Controller } from 'stimulus'
import { Loader } from '@googlemaps/js-api-loader'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['countryCode', 'address', 'administrativeArea', 'locality']

  connect() {
    fetch('/get_google_maps_api_key')
      .then(response => response.json())
      .then(data => {
        const googleMapsApiKey = data.google_maps_api_key
        window.notifyGoogleMapsLoaderAvailable = async () => {
          const event = new Event('google-maps-loader-available', { bubbles: true, cancelable: true })
          window.dispatchEvent(event)
          await initAutocomplete()
          window.GoogleMapsLoader = new Loader({
            apiKey: googleMapsApiKey,
            version: '3.57' // Every 3.XX version is deprecated after a year. Upgrade to 3.58 before May 2025.
          });
        }
        this.initAutocomplete()
      })
  }

  async initAutocomplete() {
    const self = this
    const { Autocomplete } = await GoogleMapsLoader.importLibrary('places')
    let default_value = this.countryCodeTarget.value
    var options = {
      types: ['geocode'],
      componentRestrictions: { country: default_value }
    };
    let autocomplete = new Autocomplete(self.addressTarget, options)
    autocomplete.setFields(['address_component']);
    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace()
      if (typeof place.address_components === 'undefined' ) {
        alert(I18n.t('components.google_maps.invalid_address'));
        self.addressTargets.forEach(target => target.value = '')
        return;
      } else {
        self.administrativeAreaTargets.forEach(target => {
          target.value = self.extractLongName(place.address_components, 'administrative_area_level_1')
        });
        self.localityTargets.forEach(target => {
          target.value = self.extractLongName(place.address_components, 'locality')
        });
      }
    });
  }

  extractLongName(data, targetType) {
    let longName = ''

    data.forEach(item => {
      if (item.types.includes(targetType)) {
        longName = item.long_name
      }
    })

    return longName
  }

  restartFields() {
    this.addressTargets.forEach(target => target.value = '')
    this.localityTargets.forEach(target => target.value = '')
    this.administrativeAreaTargets.forEach(target => target.value = '')
    this.initAutocomplete()
  }
}
