import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static values = {
    defaultDate: String,
    time: Boolean,
    timezone: String,
    discardDay: Boolean,
    calendarDate: String,
  }

  static targets = ['calendar', 'hourInput', 'minuteInput', 'input', 'selectedDay', 'textInput']

  connect() {
    this.addLocale();
    const monthNames = Object.values(I18n.t('date.month_names'));
    if (monthNames.length == 13) {
      monthNames.shift();
    }

    const monthShortNames = Object.values(I18n.t('date.abbr_month_names'));
    monthShortNames.shift();
    this.monthNames = monthNames
    this.monthShortNames = monthShortNames
  }

  addLocale() {
    const community_language = document.querySelector('#community_language')?.value || 'es';
    if (community_language) {
      I18n.locale = community_language;
    }
  }

  languageEnglish() {
    return I18n.locale === 'en';
  }

  hourInputTargetConnected() {
    this.hourInputTarget.addEventListener('keydown', event => this.verifyNumericInput(event))
    this.hourInputTarget.addEventListener('change', event => this.updateHour(event))
  }

  minuteInputTargetConnected() {
    this.minuteInputTarget.addEventListener('keydown', event => this.verifyNumericInput(event))
    this.minuteInputTarget.addEventListener('change', event => this.updateMinute(event))
  }

  updateHour(event) {
    this.setMinimum(event, 23)
    let formValue = this.inputTarget.value
    if (!formValue) return this.setDefaultDateTime()
    let dateObject = new Date(formValue)
    let localDateHash = this.splitLocalDate(dateObject)

    let diff = dateObject.getUTCHours() - localDateHash.hour

    dateObject.setUTCHours(parseInt(this.hourInputTarget.value) + diff)
    this.updateInputText(dateObject)
  }

  updateMinute(event) {
    this.setMinimum(event, 59)
    let formValue = this.inputTarget.value
    if (!formValue) return this.setDefaultDateTime()
    let dateObject = new Date(formValue)
    dateObject.setMinutes(parseInt(this.minuteInputTarget.value))
    this.updateInputText(dateObject)
  }

  verifyNumericInput(event) {
    if (!event.shiftKey && !event.ctrlKey && !event.metaKey && isNaN(event.key) && ![8, 9, 37, 39, 46].includes(event.keyCode)) {
      event.preventDefault()
    }
  }

  setMinimum(event, number) {
    let target = event.currentTarget
    target.value = this.timeDisplay(Math.min(parseInt(target.value), number))
  }

  changeValue(event) {
    if (this.timeValue) event.stopPropagation()
    const element = event.currentTarget;
    const dateValue = element.getAttribute('data-date');
    const dropdown = element.closest('.dropdown');
    this.switchSelectedDay(element)
    this.updateValues(dropdown, dateValue)
  }

  switchSelectedDay(newDay) {
    if (this.hasSelectedDayTarget) {
      this.selectedDayTarget.classList.remove('selected')
      this.selectedDayTarget.removeAttribute('data-date-selector-target')
    }
    newDay.classList.add('selected')
    newDay.setAttribute('data-date-selector-target', 'selectedDay')
  }

  updateValues(dropdown, date) {
    const selector = dropdown.closest('.date-selector');
    const optional = selector.querySelectorAll("input[type='hidden']")[1].value;

    if (optional == 'true' && date == ''){
      this.textInputTarget.value = date;
    } else if (this.timeValue) {
      date = date === '' ? new Date() : date;
      this.textInputTarget.value = this.formatDateTimeFromString(date, 'date_and_hour');
    } else {
      this.textInputTarget.value = this.formatDate(date);
    }

    if (!(typeof date === 'string') && this.hasHourInputTarget && this.hasMinuteInputTarget) {
      this.hourInputTarget.value = this.timeDisplay(date.getHours());
      this.minuteInputTarget.value = this.timeDisplay(date.getMinutes());
    }
    this.inputTarget.value = this.buildInputDate(date)
    this.inputTarget.dispatchEvent(new Event('change'))
    if (!this.timeValue) this.removeCalendar(dropdown)
  }

  buildInputDate(date, optional, buildWithDiff = true) {
    if (this.timeValue && date) {
      if (typeof date === 'string') {
        let dateWithHoursString = `${date} ${this.timeDisplay(this.hourInputTarget.value)}:${this.timeDisplay(this.minuteInputTarget.value)}`
        date = new Date(dateWithHoursString)
      }
      let dateArray = this.splitDate(date)
      let hour = parseInt(this.hourInputTarget.value)
      let minute = parseInt(this.minuteInputTarget.value)
      let diff = this.diffInHours(date)
      let newDate = new Date(Date.UTC(dateArray.year, dateArray.month - 1, dateArray.day, hour, minute))

      if (buildWithDiff) {
        let diff = this.diffInHours(date)
        newDate.setHours(newDate.getHours() + diff)
      }

      return newDate.toISOString()
    } else {
      return date
    }
  }

  propagateEvent(event) {
    const element = event.currentTarget
    const dropdown = element.closest('.dropdown');
    const selector = dropdown.closest('.date-selector');
    const formValue = selector.querySelector("input[type='hidden']");
    const newEvent = new Event(event.type);

    formValue.dispatchEvent(newEvent);
  }

  showCalendar(event) {
    const element = event.currentTarget
    const dropdown = element.closest('.dropdown')
    const selector = dropdown.closest('.date-selector')
    const formValue = selector.querySelector("input[type='hidden']").value
    const formDate = this.dateFormat(formValue)
    this.showWeekDays(dropdown)
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    dropdownTitle.setAttribute('data-action', 'click->date-selector#changeMonth')
    const prevMonthBtn = dropdown.querySelector('.dropdown-item.previous-month')
    const nextMonthBtn = dropdown.querySelector('.dropdown-item.next-month')
    prevMonthBtn.setAttribute('data-action', 'click->date-selector#previousMonth')
    nextMonthBtn.setAttribute('data-action', 'click->date-selector#nextMonth')
    this.addCalendar(dropdown, formDate)
  }

  showMonthCalendar(event) {
    const element = event.currentTarget
    const dropdown = element.closest('.dropdown')
    const selector = dropdown.closest('.date-selector')
    const formValue = selector.querySelector("input[type='hidden']").value
    const formDate = this.dateFormat(formValue)
    const month = formDate.getMonth() + 1
    const year = formDate.getFullYear()
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const prevMonthBtn = dropdown.querySelector('.dropdown-item.previous-month')
    const nextMonthBtn = dropdown.querySelector('.dropdown-item.next-month')
    dropdownTitle.setAttribute('data-action', 'click->date-selector#changeYear')
    dropdownTitle.setAttribute('data-month', month)
    dropdownTitle.setAttribute('data-year', year)
    dropdownTitle.innerHTML = year;
    prevMonthBtn.setAttribute('data-action', 'click->date-selector#previousYear')
    nextMonthBtn.setAttribute('data-action', 'click->date-selector#nextYear')
    this.showMonthGrid(dropdown, month, year)
  }

  showWeekDays(dropdown) {
    dropdown.querySelectorAll('.dropdown-item.day-of-the-week').forEach(dropdownItem => {
      dropdownItem.removeAttribute('style');
    });
  }

  formatDate(fullDate) {
    if (this.timeValue) {
      let formValue = this.inputTarget.value
      let dateObject = new Date(formValue)

      let hour = dateObject.getHours()
      let minutes = dateObject.getMinutes()
      let newDate = new Date(`${fullDate}`)
      return this.formatDateTime(newDate, 'date_and_hour')
    } else if (this.discardDayValue) {
      return this.formatDateTime(fullDate, 'month_and_year')
    } else {
      return this.formatDateTime(fullDate, 'default_slash')
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getCalendarRange(resultDate) {
    const date = this.splitDate(resultDate)
    const beginningOfMonth = new Date(date.year, date.month - 1, 1);
    const endOfMonth = new Date(date.year, date.month, 0);
    const startWeekDay = beginningOfMonth.getDay();
    const endWeekDay = endOfMonth.getDay();
    const previousDays = startWeekDay === 0 ? 6 : startWeekDay - 1;
    const postDays = endWeekDay === 0 ? -1 : 6 - endWeekDay;
    const totalDays = postDays + endOfMonth.getDate() + previousDays + 1
    const endIndex = totalDays < 42 ? endOfMonth.getDate() + postDays + 7 : endOfMonth.getDate() + postDays
    return [-previousDays, endIndex, endOfMonth.getDate()];
  }

  changeMonth(event) {
    event.stopPropagation();
    const element = event.currentTarget;
    const dropdown = element.closest('.dropdown');
    const month = parseInt(element.getAttribute('data-month'));
    const year = parseInt(element.getAttribute('data-year'));
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const prevMonthBtn = dropdown.querySelector('.dropdown-item.previous-month')
    const nextMonthBtn = dropdown.querySelector('.dropdown-item.next-month')
    dropdownTitle.setAttribute('data-action', 'click->date-selector#changeYear')
    dropdownTitle.setAttribute('data-month', month)
    dropdownTitle.innerHTML = year;
    prevMonthBtn.setAttribute('data-action', 'click->date-selector#previousYear')
    nextMonthBtn.setAttribute('data-action', 'click->date-selector#nextYear')
    this.removeCalendar(dropdown)

    dropdown.querySelectorAll('.dropdown-item.day-of-the-week').forEach(dropdownItem => {
      dropdownItem.style.display = 'none';
    });

    this.showMonthGrid(dropdown, month, year)
  }

  showMonthGrid(dropdown, month, year) {
    const yearSelector = dropdown.querySelector('.year-selector');
    const dropdownMenu = this.calendarTarget;
    const selectedDate = this.getSelectedDate(dropdown)
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const yearDisplayed = parseInt(dropdownTitle.getAttribute('data-year'))
    const today = this.splitDate(new Date)
    const monthGrid = document.createElement('div');
    monthGrid.classList.add('month-selector');
    if (yearSelector) {
      yearSelector.remove();
    }

    for (const monthIndex in this.monthShortNames) {
      const monthDiv = document.createElement('div');
      monthDiv.classList.add('dropdown-item-month');
      if (monthIndex == month - 1 && selectedDate.year == yearDisplayed) {
        monthDiv.classList.add('selected');
      }
      if (monthIndex == today.month - 1 && year == today.year) {
        monthDiv.classList.add('current')
      }
      monthDiv.innerHTML = this.monthShortNames[monthIndex];
      monthDiv.setAttribute('data-month', parseInt(monthIndex) + 1)
      monthDiv.setAttribute('data-year', year)

      if (this.discardDayValue) {
        monthDiv.setAttribute('data-action', 'click->date-selector#changeValue')
        monthDiv.setAttribute('data-date', this.generateMonthDate(year, parseInt(monthIndex) + 1));
      } else {
        monthDiv.setAttribute('data-action', 'click->date-selector#prepareCalendar')
      }
      monthGrid.appendChild(monthDiv);
    }

    dropdownMenu.appendChild(monthGrid);
  }

  changeYear(event) {
    event.stopPropagation();
    const element = event.currentTarget
    const year = parseInt(element.getAttribute('data-year'))
    const dropdown = element.closest('.dropdown');
    const prevMonthBtn = dropdown.querySelector('.dropdown-item.previous-month')
    const nextMonthBtn = dropdown.querySelector('.dropdown-item.next-month')
    prevMonthBtn.setAttribute('data-action', 'click->date-selector#previousRange')
    nextMonthBtn.setAttribute('data-action', 'click->date-selector#nextRange')
    this.showYearGrid(dropdown, year)
  }

  showYearGrid(dropdown, year) {
    const position = year % 12
    const minYear = year - (position - 1)
    const maxYear = year + (12 - position)
    const today = this.splitDate(new Date)
    const selectedDate = this.getSelectedDate(dropdown)
    const dropdownMenu = this.calendarTarget;
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const yearGrid = document.createElement('div');
    yearGrid.classList.add('year-selector');
    for(let yearIndex = minYear; yearIndex <= maxYear; yearIndex += 1) {
      const yearDiv = document.createElement('div');
      yearDiv.classList.add('dropdown-item-year');

      if (yearIndex == selectedDate.year) {
        yearDiv.classList.add('selected')
      }
      if (yearIndex == today.year) {
        yearDiv.classList.add('current')
      }
      yearDiv.innerHTML = yearIndex;
      yearDiv.setAttribute('data-year', yearIndex)
      yearDiv.setAttribute('data-action', 'click->date-selector#prepareMonths')
      yearGrid.appendChild(yearDiv);
    }
    dropdownMenu.appendChild(yearGrid);
    dropdownTitle.innerHTML = `${minYear} - ${maxYear}`;
    dropdownTitle.setAttribute('data-action', 'click->date-selector#stopPropagation')
    dropdown.querySelectorAll('.month-selector').forEach(monthDiv => { monthDiv.remove() })
  }

  prepareMonths(event) {
    event.stopPropagation();
    const element = event.currentTarget
    const year = parseInt(element.getAttribute('data-year'))
    const dropdown = element.closest('.dropdown');
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const month = parseInt(dropdownTitle.getAttribute('data-month'))
    const prevMonthBtn = dropdown.querySelector('.dropdown-item.previous-month')
    const nextMonthBtn = dropdown.querySelector('.dropdown-item.next-month')
    prevMonthBtn.setAttribute('data-action', 'click->date-selector#previousYear')
    nextMonthBtn.setAttribute('data-action', 'click->date-selector#nextYear')
    dropdownTitle.innerHTML = year;
    dropdownTitle.setAttribute('data-year', year)
    dropdownTitle.setAttribute('data-action', 'click->date-selector#changeYear')
    this.removeCalendar(dropdown)
    this.showMonthGrid(dropdown, month, year)
  }

  shiftMonth(event, monthOffset) {
    event.stopPropagation();
    const dropdown = event.currentTarget.closest('.dropdown');
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const month = parseInt(dropdownTitle.getAttribute('data-month')) - 1
    const year = parseInt(dropdownTitle.getAttribute('data-year'))
    const newDate = new Date(year, month + monthOffset, 1)
    this.addCalendar(dropdown, newDate)
  }

  nextMonth(event) {
    this.shiftMonth(event, 1)
  }

  previousMonth(event) {
    this.shiftMonth(event, -1)
  }

  shiftYear(event, yearOffset) {
    event.stopPropagation();
    const dropdown = event.currentTarget.closest('.dropdown');
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const year = parseInt(dropdownTitle.getAttribute('data-year'))
    const newYear = year + yearOffset
    const today = this.splitDate(new Date)
    dropdownTitle.setAttribute('data-year', newYear)
    dropdownTitle.innerHTML = newYear;
    const selectedDate = this.getSelectedDate(dropdown)
    dropdown.querySelectorAll('.dropdown-item-month').forEach(selector => {
      selector.setAttribute('data-year', newYear)
      const month = parseInt(selector.getAttribute('data-month'))
      if (month == selectedDate.month && newYear == selectedDate.year) {
        selector.classList.add('selected')
      } else {
        selector.classList.remove('selected')
      }
      if (month == today.month && newYear == today.year) {
        selector.classList.add('current')
      } else {
        selector.classList.remove('current')
      }
    })
    this.showMonthGrid(dropdown, today.month, newYear);
  }

  nextYear(event) {
    this.shiftYear(event, 1)
  }

  previousYear(event) {
    this.shiftYear(event, -1)
  }

  shiftRange(event, direction) {
    event.stopPropagation();
    const element = event.currentTarget;
    const dropdown = element.closest('.dropdown');
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    const year = parseInt(dropdownTitle.getAttribute('data-year'))
    const nextYear = year + 12 * direction
    dropdownTitle.setAttribute('data-year', nextYear)
    dropdown.querySelectorAll('.year-selector').forEach(monthDiv => { monthDiv.remove() })
    this.showYearGrid(dropdown, nextYear)
  }

  nextRange(event) {
    this.shiftRange(event, 1)
  }

  previousRange(event) {
    this.shiftRange(event, -1)
  }

  prepareCalendar(event) {
    event.stopPropagation();
    const element = event.currentTarget;
    const dropdown = element.closest('.dropdown');
    const preMonth = parseInt(element.getAttribute('data-month'))
    const preYear = parseInt(element.getAttribute('data-year'))
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    dropdownTitle.setAttribute('data-month', preMonth)
    dropdownTitle.setAttribute('data-year', preYear)
    dropdownTitle.setAttribute('data-action', 'click->date-selector#changeMonth')
    const prevMonthBtn = dropdown.querySelector('.dropdown-item.previous-month')
    const nextMonthBtn = dropdown.querySelector('.dropdown-item.next-month')
    prevMonthBtn.setAttribute('data-action', 'click->date-selector#previousMonth')
    nextMonthBtn.setAttribute('data-action', 'click->date-selector#nextMonth')
    const date = new Date(preYear, preMonth - 1, 1)
    date.setHours(0, 0, 0, 0)
    this.removeCalendar(dropdown)
    this.showWeekDays(dropdown)
    this.addCalendar(dropdown, date)
  }

  getSelectedDate(dropdown) {
    const selector = dropdown.closest('.date-selector');
    const date = selector.querySelector("input[type='hidden']").value

    return this.splitDate(date);
  }

  addCalendar(dropdown, date) {
    const newMonth = date.getMonth()
    const newYear = date.getFullYear()
    const newDate = this.splitDate(date)
    const selector = dropdown.closest('.date-selector');
    const optional = selector.querySelectorAll("input[type='hidden']")[1].value
    let formValue

    if (this.timeValue) {
      formValue = this.toLocalDateIso(selector.querySelector("input[type='hidden']").value, optional)
    } else {
      formValue = selector.querySelector("input[type='hidden']").value;
    }

    if (formValue == '') {
      formValue = this.datePart(new Date());
    }
    const preselectedDate = this.splitDate(formValue)
    let varDate = new Date()
    if (formValue != ''){
      varDate = new Date(newDate.year, newDate.month - 1, 1);
    }

    const dropdownMenu = this.calendarTarget;
    const dropdownTitle = dropdown.querySelector('.dropdown-item.current-month')
    dropdownTitle.textContent = `${this.monthNames[parseInt(newMonth)]} ${newYear}`;
    dropdownTitle.setAttribute('data-month', newDate.month)
    dropdownTitle.setAttribute('data-year', newDate.year)
    this.removeCalendar(dropdown)

    const [start_index, end_index, days_of_month] = this.getCalendarRange(varDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    varDate.setDate(varDate.getDate() + start_index - 1);
    for (let i = start_index; i <= end_index; i += 1) {
      const day = document.createElement('div');
      varDate.setDate(varDate.getDate() + 1);

      day.classList.add('dropdown-item', 'selectable');

      if (i < 0 || i >= days_of_month) {
        day.classList.add('date-of-another-month');
      } else {
        day.classList.add('date-of-the-month');
      }

      if (preselectedDate && this.sameDate(formValue, varDate)) {
        day.classList.add('selected')
        day.setAttribute('data-date-selector-target', 'selectedDay');
      }

      if (this.sameDate(today, varDate)) {
        day.classList.add('today');
      }

      if (this.validDate(dropdown, varDate)) {
        day.setAttribute('data-action', 'click->date-selector#changeValue')
      } else {
        day.classList.add('date-disabled');
        day.setAttribute('data-action', 'click->date-selector#preventClose')
      }

      day.setAttribute('data-date', this.datePart(varDate));

      day.innerHTML = varDate.getDate();
      dropdownMenu.appendChild(day);
    }
  }

  preventClose(event) {
    event.stopPropagation();
  }

  datePart(date) {
    return date.toISOString().split('T')[0]
  }

  splitDate(date) {
    let data = typeof(date) == 'string' ? date : this.datePartFromLocalISOString(date)

    const array = data.split('-')
    return { year: parseInt(array[0]), month: parseInt(array[1]), day: parseInt(array[2]) }
  }

  splitDateFromLocalISOString(date) {
    let data = typeof(date) == 'string' ? date : this.datePartFromLocalISOString(date)
    const array = data.split('-')
    return { year: parseInt(array[0]), month: parseInt(array[1]), day: parseInt(array[2]) }

  }

  removeCalendar(dropdown) {
    dropdown.querySelectorAll('.dropdown-item.selectable, .month-selector, .year-selector').forEach(dropdownItem => {
      dropdownItem.remove();
    });
  }

  dateFormat(string) {
    if (string == '') return new Date()

    if (this.timeValue) {
      let stringDateTimeWithTimeZone = new Date(string).toLocaleDateString('en', { timeZone: this.timezoneValue, hour12: false })
      let DateWithTimeZone = stringDateTimeWithTimeZone.split('/')
      return new Date(DateWithTimeZone[2], parseInt(DateWithTimeZone[0]) - 1, DateWithTimeZone[1])
    } else {
      const date = this.splitDate(string)
      return new Date(date.year, date.month - 1, date.day)
    }
  }

  sameDate(date_1, date_2) {
    const date1 = this.splitDate(date_1)
    const date2 = this.splitDate(date_2)

    return date1.day == date2.day && date1.month == date2.month && date1.year == date2.year
  }

  updateDate(event) {
    const element = event.currentTarget
    const dropdown = element.closest('.dropdown');
    const selector = dropdown.closest('.date-selector');
    const formValue = selector.querySelector("input[type='hidden']").value;
    const input = selector.querySelector("input[type='text']");
    const inputValue = input.value
    const onlyNumber = inputValue.replace(/\D/g, '');
    const todayObject = new Date()
    const today = this.splitDate(todayObject)
    const optional = selector.querySelectorAll("input[type='hidden']")[1].value;
    let dayString
    let monthString
    let yearString
    let hourString
    let minuteString
    if (inputValue != onlyNumber) {
      if (onlyNumber) {
        if (this.discardDayValue) {
          [monthString, yearString] = inputValue.split(/\D/g).filter(Number)
        } else {
          if (this.languageEnglish()) {
            [monthString, dayString, yearString, hourString, minuteString] = inputValue.split(/[\/\-\s:]+/g)
          } else {
            [dayString, monthString, yearString, hourString, minuteString] = inputValue.split(/[\/\-\s:]+/g)
          }

          if (isNaN(yearString)) {
            yearString = today.year.toString();
          }

          if (isNaN(hourString)) {
            hourString = todayObject.getHours().toString();
          }

          if (isNaN(minuteString)) {
            minuteString = todayObject.getMinutes().toString();
          }
        }
      } else {
        if (formValue == '') {
          input.value = ''
          return
        }
        input.value = this.formatDate(formValue)
        return
      }
    } else {
      if(this.discardDayValue) {
        monthString = onlyNumber.substring(0, 2)
        yearString = onlyNumber.substring(2, 6)
      } else {
        if (this.languageEnglish()) {
          monthString = onlyNumber.substring(0, 2)
          dayString = onlyNumber.substring(2, 4)
        } else {
          dayString = onlyNumber.substring(0, 2)
          monthString = onlyNumber.substring(2, 4)
        }
        yearString = onlyNumber.substring(4, 8)
      }
    }
    let year
    let month
    let day
    let hour
    let minute
    if (yearString) {
      year = parseInt(yearString)
      if (yearString.length == 2) {
        const currentCentury = Math.floor(today.year / 100) * 100
        year = currentCentury + year
      } else {
        year = year > 0 ? year : today.year
      }
    } else {
      year = today.year
    }
    if (monthString) {
      month = parseInt(monthString)
      month = month > 0 ? month : today.month
    } else {
      month = today.month
    }
    if (dayString) {
      day = parseInt(dayString)
      day = day > 0 ? day : today.day
    } else {
      if(this.discardDayValue) {
        day = 1
      } else {
        day = today.day
      }
    }

    if (hourString) {
      if (hourString.length > 2) {
        hour = parseInt(hourString.substring(0, 2))
      } else {
        hour = parseInt(hourString)
      }

      if (hour < 0 || hour > 23) {
        hour = todayObject.getHours()
      }
    }

    if (minuteString) {
      if (minuteString.length > 2) {
        minute = parseInt(minuteString.substring(0, 2))
      } else {
        minute = parseInt(minuteString)
      }

      if (minute < 0 || minute > 59) {
        minute = todayObject.getMinutes()
      }
    }

    if (optional == 'true' && inputValue == '') {
      this.updateValues(dropdown, '')
    } else if (optional == 'false' && inputValue == '') {
      this.updateValues(dropdown, new Date())
    } else {
      let newDateTime = new Date(year, month - 1, day, hour, minute)
      if (this.validDate(dropdown, newDateTime)) {
        if (this.hasHourInputTarget && this.hasMinuteInputTarget) {
          this.updateValues(dropdown, newDateTime)
        } else {
          let newDate = this.datePart(new Date(year, month - 1, day))
          this.updateValues(dropdown, newDate)
        }
      } else {
        if (this.timeValue) {
          let stringDateTimeWithTimeZone = new Date(formValue).toLocaleDateString('en', { timeZone: this.timezoneValue, hour12: false })
          let DateWithTimeZone = stringDateTimeWithTimeZone.split('/')
          let newDate =  new Date(DateWithTimeZone[2], parseInt(DateWithTimeZone[0]) - 1, DateWithTimeZone[1])

          this.updateValues(dropdown, this.datePart(newDate))
        } else {
          let dateFormValue = formValue.split('-')
          let newDateFormValue = new Date(dateFormValue[0], parseInt(dateFormValue[1])-1, dateFormValue[2]);

          this.updateValues(dropdown, this.datePart(newDateFormValue))
        }
      }
    }
  }

  validDate(dropdown, date) {
    const selector = dropdown.closest('.date-selector');

    const dateFrom = selector.querySelectorAll('#start-date-range')[0]?.value;
    const dateTo = selector.querySelectorAll('#end-date-range')[0]?.value;

    if (dateFrom == '' || dateTo == '') {
      return true;
    }

    let date1 = dateFrom.split('-');
    let date2 = dateTo.split('-');
    let from = new Date(date1[0], parseInt(date1[1])-1, date1[2]);  // -1 because months are from 0 to 11
    let to   = new Date(date2[0], parseInt(date2[1])-1, date2[2]);

    let check = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    return !(check < from || check > to)
  }

  // time selector

  toLocalDateIso(string_date, optional) {
    if (string_date == '' && optional == 'true') return ''
    let stringDateTimeWithTimeZone = new Date(string_date).toLocaleString('en', { timeZone: this.timezoneValue, hour12: false })
    let DateWithTimeZone = stringDateTimeWithTimeZone.split(', ')[0].split('/')
    let TimeWithTimeZone = stringDateTimeWithTimeZone.split(', ')[1].split(':')

    let year = DateWithTimeZone[2]
    let month = DateWithTimeZone[0]
    let day = DateWithTimeZone[1]

    let hour = TimeWithTimeZone[0]
    let minute = TimeWithTimeZone[1]

    return `${year}-${this.timeDisplay(month)}-${this.timeDisplay(day)}T${this.timeDisplay(hour)}:${this.timeDisplay(minute)}:00`
  }

  addHour(event) {
    event.stopPropagation()
    this.shiftHour(1, true)
  }

  substractHour(event) {
    event.stopPropagation()
    this.shiftHour(-1, true)
  }

  shiftHour(direction, sum_direction) {
    let hour = parseInt(this.hourInputTarget.value)
    let formValue = this.inputTarget.value
    if (!formValue) return this.setDefaultDateTime()
    let dateObject = new Date(formValue)
    if (sum_direction) { dateObject.setHours(dateObject.getHours() + direction) }

    if (this.datetimeWithinRange(dateObject)) { return }

    this.updateInputText(dateObject)

    let condition = direction > 0 ? hour >= 23 : hour <= 0
    let value = direction > 0 ? '00' : '23'

    if (condition) {
      this.hourInputTarget.value = value
      this.shiftDay(direction)
    } else {
      this.hourInputTarget.value = this.timeDisplay(hour + direction)
    }
  }

  setDefaultDateTime() {
    let date = this.calendarDateValue
    this.textInputTarget.value = this.formatDateTimeFromString(date, 'date_and_hour')
    this.inputTarget.value = this.buildInputDate(date, false)
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  getDayElement(date) {
    return this.calendarTarget.querySelector(`[data-date='${date}']`)
  }

  shiftDay(direction) {
    let formValue = this.inputTarget.value
    let dateObject = new Date(formValue)
    this.updateInputText(dateObject)
    let date = this.selectedDayTarget.dataset.date
    let newDate = this.addDays(date, direction)
    let newElement = this.getDayElement(newDate)
    this.switchSelectedDay(newElement)
  }

  addMinute(event) {
    event.stopPropagation()
    this.shiftMinute(1)
  }

  substractMinute(event) {
    event.stopPropagation()
    this.shiftMinute(-1)
  }

  shiftMinute(direction) {
    let formValue = this.inputTarget.value
    if (!formValue) return this.setDefaultDateTime()
    let dateObject = new Date(formValue)
    dateObject.setMinutes(dateObject.getMinutes() + direction)

    if (this.datetimeWithinRange(dateObject)) { return }

    this.updateInputText(dateObject)
    let minute = parseInt(this.minuteInputTarget.value)
    let condition = direction > 0 ? minute >= 59 : minute <= 0
    let value = direction > 0 ? '00' : '59'

    if (condition) {
      this.minuteInputTarget.value = value
      this.shiftHour(direction, false)
    } else {
      this.minuteInputTarget.value = this.timeDisplay(minute + direction)
    }
  }

  datetimeWithinRange(dateObject) {
    const element = event.currentTarget
    const dropdown = element.closest('.dropdown');
    const selector = dropdown.closest('.date-selector');
    const dateFrom = selector.querySelectorAll('#start-date-range')[0]?.value;
    const dateTo = selector.querySelectorAll('#end-date-range')[0]?.value;

    let date1 = dateFrom.split('-');
    let date2 = dateTo.split('-');
    let from = new Date(Date.UTC(date1[0], parseInt(date1[1])-1, date1[2], 0, 0, 0));  // -1 because months are from 0 to 11
    let to   = new Date(Date.UTC(date2[0], parseInt(date2[1])-1, date2[2], 23, 59, 0));

    let stringDateTimeWithTimeZone = dateObject.toLocaleDateString('en', { timeZone: this.timezoneValue, hour12: false })
    let DateWithTimeZone = stringDateTimeWithTimeZone.split('/')

    let formDate =  new Date(Date.UTC(DateWithTimeZone[2], parseInt(DateWithTimeZone[0])-1, DateWithTimeZone[1]));

    if ((formDate > to) || (formDate < from)) {
      return true
    }else{
      return false
    }

  }

  updateInputText(date) {
    this.textInputTarget.value = this.formatDateTime(date, 'date_and_hour')
    this.inputTarget.value = date.toISOString()
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  addDays(date, days) {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return this.datePart(result)
  }

  timeDisplay(num) {
    return String(num).padStart(2, '0')
  }

  formatDateTime(datetime, format) {
    if (this.timeValue) {
      let stringDateTimeWithTimeZone = datetime.toLocaleString('en', { timeZone: this.timezoneValue, hour12: false })
      let TimeWithTimeZone = stringDateTimeWithTimeZone.split(', ')[1].split(':')
      let DateWithTimeZone = stringDateTimeWithTimeZone.split(', ')[0].split('/')

      const year = DateWithTimeZone[2]
      const month = this.timeDisplay(DateWithTimeZone[0])
      const day = this.timeDisplay(DateWithTimeZone[1])
      const hours = this.timeDisplay(TimeWithTimeZone[0])
      const minutes = this.timeDisplay(TimeWithTimeZone[1])
      const string = I18n.t(`datetime.formats.${format}`)
      return string.replace('%m', month).replace('%d', day).replace('%Y', year).replace('%H', hours).replace('%M', minutes)
    } else if (this.discardDayValue) {
      let date = this.splitDate(datetime)
      const year = date.year
      const month = this.timeDisplay(date.month)
      const string = I18n.t(`datetime.formats.${format}`)
      return string.replace('%m', month).replace('%Y', year)
    } else {
      let date = this.splitDate(datetime)
      const year = date.year
      const month = this.timeDisplay(date.month)
      const day = this.timeDisplay(date.day)
      const string = I18n.t(`datetime.formats.${format}`)
      return string.replace('%m', month).replace('%d', day).replace('%Y', year)
    }
  }

  formatDateTimeFromString(date, format){
    let dateIsString = typeof date === 'string'
    let arrayDate = this.splitDateFromLocalISOString(date)
    const year = arrayDate.year
    const month = this.timeDisplay(arrayDate.month)
    const day = this.timeDisplay(arrayDate.day)
    const hours = dateIsString ? this.timeDisplay(this.hourInputTarget.value) : this.timeDisplay(date.getHours())
    const minutes = dateIsString ? this.timeDisplay(this.minuteInputTarget.value) : this.timeDisplay(date.getMinutes())
    const string = I18n.t(`datetime.formats.${format}`)

    const content_to_replace = string.replace('%m', month).replace('%d', day).replace('%Y', year).replace('%H', hours).replace('%M', minutes)
    return content_to_replace
  }

  splitLocalDate(dateObject){
    let stringDateTimeWithTimeZone = dateObject.toLocaleString('en', { timeZone: this.timezoneValue, hour12: false })
    let TimeWithTimeZone = stringDateTimeWithTimeZone.split(', ')[1].split(':')
    let DateWithTimeZone = stringDateTimeWithTimeZone.split(', ')[0].split('/')

    const year = DateWithTimeZone[2]
    const month = DateWithTimeZone[0]
    const day = DateWithTimeZone[1]
    const hours = TimeWithTimeZone[0]
    const minutes = TimeWithTimeZone[1]

    return { year: parseInt(year), month: parseInt(month), day: parseInt(day), hour: parseInt(hours), minutes: parseInt(minutes) }
  }

  diffInHours(date) {
    if (this.timezoneValue == 'America/Santiago'){
      return date.getTimezoneOffset() / 60
    }
    let formValue = this.inputTarget.value
    if (!formValue && date) {
      formValue = this.buildInputDate(date, false, false)
    }
    let dateObject = new Date(formValue)
    let iso = dateObject.toLocaleString('en-CA', { timeZone: this.timezoneValue, hour12: false }).replace(', ', 'T')
    iso += '.000Z'

    const lie = new Date(iso)
    return (dateObject  - lie) / 1000 / 60 / 60
  }

  toLocalISOString(date) {
    if (typeof date === 'string') {
      date = new Date(date)
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  datePartFromLocalISOString(date) {
    return this.toLocalISOString(date).split('T')[0]
  }
  generateMonthDate(year, month) {
    const parsedMonth = month.toString().padStart(2, '0')

    return `${year}-${parsedMonth}-01`;
  }
}
